import { UseToastOptions, useToast } from '@chakra-ui/react';
import { DefaultError } from '@tanstack/react-query';

export function showErrorToast<TError = DefaultError>(
  toast: ReturnType<typeof useToast>,
  error: string | TError | undefined | null | unknown,
  options?: UseToastOptions
) {
  if (error) {
    toast.closeAll();
  }
  toast({
    status: 'error',
    title: 'An error ocurred',
    description: typeof error === 'string' ? error : (error as DefaultError | undefined)?.message,
    isClosable: true,
    duration: 2500,
    ...options,
  });
}
