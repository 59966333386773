import { useToast, Stack, Box } from '@chakra-ui/react';
import { ActionDisplayInfo, AiChatMessage, ChatMessage, resolveContentText } from '@worknet/models';
import { ComponentProps, useEffect, useState } from 'react';

import useChatStore from '~/shared/utils/useChatStore';

import ServerMessage from '../../ds/ServerMessage';
import UserInput from '../../ds/UserInput';
import UserMessage from '../../ds/UserMessage';
import TextboxModal from '../../ds/TextboxModal';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import ServerLoadingMessage from '../../ds/ServerLoadingMessage';
import { createTagGroups, fadeIn } from '../utils';

import MessageContainer from './MessageContainer';
import DefaultMessage from './DefaultMessage';
import ScrollPoint from './ScrollPoint';
import CheckboxWithText from './CheckboxWithText';
import { formatMessageWithHtml, getCitationsButtonsData } from './utils';
import CitationsButtons from './CitationsButtons';

type SubmitType = Parameters<NonNullable<ComponentProps<typeof ServerMessage>['onSubmit']>>[0];
type ModelConfig = Omit<ComponentProps<typeof TextboxModal<ActionDisplayInfo>>, 'onClose'>;

function getCitationsButtons(msg: AiChatMessage, index?: number) {
  if ('error' in msg.content) {
    return null;
  }
  const citationsButtonsData = getCitationsButtonsData(msg.content.citations, msg.content.sources);
  const allCitationButtons = citationsButtonsData.map((buttonsData, idx) => (
    <CitationsButtons key={idx} buttonsData={buttonsData} />
  ));
  return (
    <Box as="span" data-is-citation="true">
      {allCitationButtons[index ?? 0] || null}
    </Box>
  );
}

function getElementWithoutCitations(element: HTMLElement) {
  const clonedElement = element.cloneNode(true) as HTMLElement;
  clonedElement.querySelectorAll('[data-is-citation="true"]').forEach((el) => el.remove());
  return clonedElement;
}

export default function Messages() {
  const {
    appValue,
    chatValue,
    user,
    groupingId,
    sendMessage,
    setMessageLiked,
    setChatSharingConfirmed,
    featureOverrides,
  } = useChatStore(
    ({
      app,
      chat,
      hostPlatformContext,
      //
      sendMessage,
      setMessageLiked,
      setChatSharingConfirmed,
    }) => ({
      ...hostPlatformContext!,
      appValue: app.value!,
      chatValue: chat!.value!,
      sendMessage,
      setMessageLiked,
      setChatSharingConfirmed,
    })
  );

  const [interactionLoading, setInteractionLoading] = useState(false);
  const [modalConfig, setCurrentModalConfig] = useState<ModelConfig>();

  const chatOwner = chatValue.chat?.user;
  const isChatOwner = !chatValue.messages.at(0) || chatOwner?.email === user?.email;

  const [activeScroll, setActiveScroll] = useState(true);

  const toast = useToast();

  const isBotTyping = !!chatValue.progressingBotMessage;
  useEffect(() => {
    setActiveScroll(!!chatValue.progressingBotMessage);
  }, [chatValue.progressingBotMessage]);

  const isSharedCheckboxShown =
    !!appValue.sharingApprovalRequired &&
    !!groupingId &&
    !!appValue.groupingSupported &&
    !chatValue.chat;

  function onTextboxModalClose(action: ActionDisplayInfo, text?: string) {
    setCurrentModalConfig(undefined);
    if (!text) {
      return;
    }
    sendMessage({ actionId: action.id, content: text });
  }

  function onSendFeedbackSuccess(isLiked: boolean) {
    toast({
      title: isLiked ? 'Liked' : 'Disliked',
      status: 'success',
      description: 'Thank you for your feedback',
      isClosable: true,
      duration: 3000,
    });
  }

  function onActionClick(action: ActionDisplayInfo) {
    if (action.userInputRequired) {
      setCurrentModalConfig({
        data: action,
        button: 'Submit',
        placeholder: 'Enter text',
        title: action.displayName,
        required: true,
      });
      return;
    }

    sendMessage({ actionId: action.id, content: action.displayName });
  }

  async function onServerMessageCommand(
    type: SubmitType,
    value: string,
    msg: ChatMessage,
    args: Record<string, string>
  ) {
    if (msg.role !== 'ai' || 'error' in msg.content) {
      return;
    }
    try {
      setInteractionLoading(true);
      if (type === 'button') {
        switch (value) {
          case 'dislike':
          case 'like':
            {
              setMessageLiked(msg.id, value === 'like', args?.text, onSendFeedbackSuccess);
            }
            break;
          case 'copy':
            {
              const el = document.querySelector<HTMLElement>(
                `[data-message-id="${msg.id}"] .worknet-markdown`
              );
              const elWithoutCitations = getElementWithoutCitations(el!);
              if (featureOverrides?.copyToClipboard) {
                await featureOverrides.copyToClipboard?.(elWithoutCitations, msg.content.message);
              } else {
                await copyToClipboard(elWithoutCitations, msg.content.message);
              }
              toast({
                title: 'Copied!',
                status: 'success',
                description: 'Thanks message was copied',
                isClosable: false,
                duration: 500,
              });
            }
            break;
        }
      } else {
        window.open(value, '_blank');
      }
    } catch (error) {
      toast({
        title: 'Error',
        status: 'error',
        description: (error as Error).message,
        isClosable: true,
      });
    } finally {
      setInteractionLoading(false);
    }
  }

  const isDefaultMessageDisplayed =
    chatValue.messages.length === 0 && !(appValue?.autoAction && chatValue?.sending);

  const isCopyButtonHidden = featureOverrides?.copyToClipboard === false;

  return (
    <>
      <Stack
        h="100%"
        pointerEvents={chatValue.sending || chatValue.progressingBotMessage ? 'none' : 'auto'}
      >
        {isDefaultMessageDisplayed ? (
          <Box flex={1} animation={`${fadeIn} 0.6s`}>
            <DefaultMessage
              chatSettings={appValue}
              actions={appValue.actions}
              onActionClick={onActionClick}
              user={user}
            />
          </Box>
        ) : (
          <Stack gap="20px" flex={1} overflowY="auto">
            {chatValue.messages.map((msg, i) => {
              const isAiMessage = msg.role === 'ai';
              const isLastMessage = i === chatValue.messages.length - 1;
              const otherUsersArr = Object.values(chatValue.chat?.otherUsers || {});

              const msgUser =
                msg.role === 'human'
                  ? otherUsersArr.find(({ email }) => email === msg.userEmail)
                  : chatOwner;

              return (
                <MessageContainer
                  key={i}
                  createdAt={new Date(msg.audit.createdAt)}
                  isBot={isAiMessage}
                  chatSettings={appValue}
                  name={msgUser?.name}
                  imageSrc={msgUser?.picture}
                >
                  {msg.role === 'ai' ? (
                    <ServerMessage
                      hiddenButtons={
                        !isLastMessage || !isChatOwner
                          ? 'all'
                          : isCopyButtonHidden
                            ? 'copy'
                            : undefined
                      }
                      messageId={msg.id}
                      message={
                        appValue.disclaimer
                          ? `${formatMessageWithHtml(msg)}<br />${appValue.disclaimer}`
                          : formatMessageWithHtml(msg)
                      }
                      isError={!!('error' in msg.content)}
                      components={{ span: ({ index }) => getCitationsButtons(msg, index) }}
                      liked={msg?.feedbacks?.[0]?.isHelpful}
                      loading={interactionLoading}
                      onSubmit={(type, value, args) =>
                        onServerMessageCommand(type, value, msg, args)
                      }
                      tagGroups={createTagGroups('error' in msg.content ? [] : msg.content.sources)}
                    />
                  ) : (
                    <UserMessage
                      key={i}
                      isSameUser={msg.role === 'user'}
                      message={resolveContentText(msg.content)}
                    />
                  )}
                </MessageContainer>
              );
            })}
            {chatValue.progressingBotMessage && (
              <MessageContainer createdAt={new Date()} isBot chatSettings={appValue}>
                <ServerLoadingMessage message={chatValue.progressingBotMessage.content} />
              </MessageContainer>
            )}
            <ScrollPoint active={activeScroll} />
          </Stack>
        )}

        {isChatOwner && (
          <Stack mt="auto" gap="11px">
            {appValue.amaAction.userInputRequired && (
              <UserInput
                submitDisabled={false}
                isBotTyping={isBotTyping}
                onSubmit={(text) => {
                  sendMessage({ actionId: appValue.amaAction.id, content: text });
                  setActiveScroll(true);
                }}
              />
            )}

            {isSharedCheckboxShown && (
              <CheckboxWithText
                isChecked={!!chatValue.sharingConfirmed}
                onChange={() => setChatSharingConfirmed(!chatValue.sharingConfirmed)}
                text="This conversation will be shared with your group."
              />
            )}
          </Stack>
        )}
      </Stack>
      {!!modalConfig && <TextboxModal onClose={onTextboxModalClose} {...modalConfig} />}
      {/* {!!confirmationModalConfig && <ConfirmationModal {...confirmationModalConfig} />} */}
    </>
  );
}
