import { Box, Center, Flex, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import { ComponentProps, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { markdownStyles } from '~/shared/utils/theme';

import ErrorMarkIcon from './assets/ErrorMark.svg?react';
import CopySvg from './assets/IoCopySharp.svg?react';
import ThumbsDownSvg from './assets/MdThumbDownAlt.svg?react';
import ThumbsUpSvg from './assets/MdThumbUpAlt.svg?react';
import Markdown, { MarkdownProps } from './Markdown';
import TextboxModal from './TextboxModal';

type TagGroup = {
  title: string;
  tags: { title: string; content?: string }[];
  isLink?: boolean;
};

type Props = {
  messageId?: string;
  message: string;
  hiddenButtons?: 'all' | 'copy';
  components?: MarkdownProps['components'];
  liked?: boolean;
  loading?: boolean;
  tagGroups?: TagGroup[];
  showAvatar?: boolean;
  isError?: boolean;
  onSubmit?: (type: 'button' | 'tag', value: string, args: Record<string, string>) => void;
};

function TagGroups({ tagGroups, onSubmit }: Pick<Props, 'tagGroups' | 'onSubmit'>) {
  return (
    <>
      {tagGroups
        ?.filter?.(({ tags }) => !!tags?.length)
        .map?.(({ title, tags, isLink }, i) => (
          <VStack key={i} alignItems="start" marginTop="1">
            <Text fontSize="chatTinyText" fontWeight="bold">
              {title}:
            </Text>
            <Flex flexWrap="wrap" gap="11px">
              {tags.map((t, i) => (
                <Center
                  h="24px"
                  px="8px"
                  bg="white"
                  borderRadius="4px"
                  border="1px"
                  borderColor="greyNatural"
                  cursor="pointer"
                  transition="0.15s"
                  _hover={{ bg: '#B8B8B8' }}
                  key={i}
                  onClick={isLink ? () => onSubmit?.('tag', t.content!, {}) : undefined}
                >
                  <Text fontSize="chatTinyText" fontWeight="bold" maxW="40ch" isTruncated>
                    {t.title}
                  </Text>
                </Center>
              ))}
            </Flex>
          </VStack>
        ))}
    </>
  );
}

function IconButton({
  title,
  svg,
  onClick,
  active,
  disabled,
}: {
  title: string;
  svg: typeof ThumbsUpSvg;
  onClick?: ComponentProps<typeof Box>['onClick'];
  active?: boolean;
  disabled?: boolean;
}) {
  const isDisabled = disabled || active;

  return (
    <Box
      title={title}
      fontSize="title"
      lineHeight="16px"
      as="button"
      color={active ? 'main' : '#545F715C'}
      _hover={isDisabled ? {} : { color: '#545F7199' }}
      border={'none'}
      onClick={isDisabled ? null : onClick}
      cursor={isDisabled ? 'default !important' : 'pointer'}
    >
      <Icon as={svg} boxSize="15px" />
    </Box>
  );
}

const modalConfigs = {
  iterate: {
    title: 'Refine the generated response',
    placeholder: `Provide guidance like: "Shorten it and make it friendly"`,
    button: 'Refine',
    required: true,
    data: 'iterate',
  },
  like: {
    title: 'Mark this answer as helpful',
    placeholder: 'Additional textual feedback (optional)',
    button: 'Send feedback',
    data: 'like',
  },
  dislike: {
    title: 'Mark this answer as not helpful',
    placeholder: 'Additional textual feedback (optional)',
    button: 'Send feedback',
    data: 'dislike',
  },
};

function Buttons({
  loading,
  liked,
  hiddenButtons,
  onSubmit,
}: {
  loading?: boolean;
  liked?: boolean;
  hiddenButtons?: 'all' | 'copy';
  onSubmit: Props['onSubmit'];
}) {
  const disabled = loading || liked !== undefined;
  const [modalAction, setModalAction] = useState<string>();
  const modalConfig = modalConfigs[modalAction as keyof typeof modalConfigs];

  function onTextboxModalClose(_: string, text: string | undefined) {
    setModalAction(undefined);
    const isCancel = text === undefined;
    if (isCancel) {
      return;
    }
    onSubmit?.('button', modalAction!, { text });
  }

  if (hiddenButtons === 'all') {
    return null;
  }

  return (
    <>
      <Flex display={'flex'} justifyContent="end" gap="6px">
        <IconButton
          active={liked === true}
          disabled={disabled}
          svg={ThumbsUpSvg}
          title="Like"
          onClick={() => {
            setModalAction('like');
          }}
        />
        <IconButton
          active={liked === false}
          disabled={disabled}
          svg={ThumbsDownSvg}
          title="Dislike"
          onClick={() => {
            setModalAction('dislike');
          }}
        />
        {hiddenButtons !== 'copy' && (
          <IconButton
            disabled={loading}
            svg={CopySvg}
            title="Copy to clipboard"
            onClick={() => onSubmit?.('button', 'copy', {})}
          />
        )}
      </Flex>
      {!!modalConfig && <TextboxModal onClose={onTextboxModalClose} {...modalConfig} />}
    </>
  );
}

export default function ServerMessage({
  messageId,
  message,
  tagGroups,
  components,
  hiddenButtons,
  liked,
  loading,
  onSubmit,
  isError,
}: Props) {
  return (
    <Stack
      data-message-id={messageId}
      width="100%"
      fontSize="chatText"
      alignSelf="self-start"
      gap="9px"
      className={markdownStyles}
    >
      <Stack bg="#F2F2F2" color="black" borderRadius={8} p="10px 16px" gap={2} flex="1">
        <Box pos="relative">
          {isError ? (
            <ErrorMessage error={message} />
          ) : (
            <Markdown content={message} components={components} />
          )}
        </Box>

        <TagGroups tagGroups={tagGroups} onSubmit={onSubmit} />
      </Stack>
      {hiddenButtons !== 'all' && !isError && (
        <Buttons
          liked={liked}
          loading={loading}
          onSubmit={onSubmit}
          hiddenButtons={hiddenButtons}
        />
      )}
    </Stack>
  );
}

function ErrorMessage({ error }: { error: string }) {
  return (
    <Flex gap="5px" align="center">
      <Icon
        _focus={{ outline: 'none' }}
        data-tooltip-id="message-error-text"
        as={ErrorMarkIcon}
        boxSize="14px"
        mt="1px"
      />
      <Tooltip
        id="message-error-text"
        place="bottom-start"
        noArrow
        content={`Error: ${error}`}
        style={{
          boxShadow: '0px 0px 15px 0px #0000001A',
          backgroundColor: 'white',
          borderRadius: '6px',
          padding: '5px',
          color: 'red',
          maxWidth: '350px',
        }}
      />

      <Text color="red">AI assistant encountered an error.</Text>
    </Flex>
  );
}
