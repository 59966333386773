import { Box, Center, Flex, FlexProps, Icon, IconProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { User } from '@auth0/auth0-react';

import LogoutIcon from '~/shared/components/ds2/assets/Logout.svg?react';
import MenuIcon from '~/shared/components/ds2/assets/Menu.svg?react';
import NewIcon from '~/shared/components/ds2/assets/New.svg?react';
import Menu, { MenuProps } from '~/shared/components/ds/Menu';
import { useSafeLogout } from '~/shared/utils/useSafeLogout';

interface HeaderProps extends FlexProps {
  customMenuItems?: MenuProps['items'];
  customHeader?: ReactNode;
  newButtonProps?: IconProps & { isDisabled?: boolean };
  user?: User;
}

const iconSharedProps: IconProps = {
  boxSize: '18px',
  pos: 'relative',
  cursor: 'pointer',
  transition: '0.2s',
  fill: 'greyDark',
};
const iconDisabledProps: IconProps = {
  opacity: 0.32,
  cursor: 'not-allowed',
  fill: 'greyDark !important',
  onClick: () => {},
};
export default function Header({
  customMenuItems,
  customHeader,
  newButtonProps,
  user,
  ...rest
}: HeaderProps) {
  const logout = useSafeLogout();
  const { isDisabled, ...restNewButtonProps } = newButtonProps || {};

  const menuItems = [
    ...(customMenuItems || []),
    ...(user
      ? [
          {
            text: 'Logout',
            IconSvg: LogoutIcon,
            onClick: () => logout(),
          },
        ]
      : []),
  ];

  return (
    <Flex h="40px" flexShrink={0} align="center" justify="space-between" {...rest}>
      {menuItems.length ? (
        <Menu
          MenuButton={
            <Center>
              <Icon {...iconSharedProps} _groupHover={{ fill: 'primaryDark' }} as={MenuIcon} />
            </Center>
          }
          items={menuItems}
          pos="relative"
          bottom="8px"
          left="4px"
        />
      ) : (
        <Box />
      )}

      {customHeader || (
        <Icon
          as={NewIcon}
          _hover={{ fill: 'primaryDark' }}
          {...iconSharedProps}
          {...restNewButtonProps}
          {...(isDisabled ? iconDisabledProps : {})}
        />
      )}
    </Flex>
  );
}
