import {
  ChatActionsResponse,
  ErrorPayload,
  ChatSummaryItem,
  ChatSettings,
  Chat,
  ChatMessageResponse,
} from '@worknet/models';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { HostPlatformContext } from './api';

type AsyncState<T> = {
  loading?: boolean;
  // error?: Error; // error removed from here and managed on the global store
  value?: T;
};

type ProgressingBotMessage = { replyToMessageId: string; content: string };
interface State {
  hostPlatformContext?: HostPlatformContext;
  app: AsyncState<ChatSettings & ChatActionsResponse>;
  chats: ChatSummaryItem[];
  chat?: AsyncState<{
    chat?: Chat;
    messages: ChatMessageResponse[];
    progressingBotMessage?: ProgressingBotMessage;
    sending?: boolean;
    sharingConfirmed?: boolean;
  }>;
  error?: Error;
}

type ChatMessageInfo = { content: string; actionId: string };

interface Actions {
  init(hostPlatformContext: HostPlatformContext): void;
  openChat(chatId?: string): void;
  closeChat(): void;
  sendMessage(info: ChatMessageInfo): void;
  setChatSharingConfirmed(sharingConfirmed: boolean): void;
  setMessageLiked(
    messageId: string,
    isLiked: boolean,
    comment: string,
    onSuccess: (isLiked: boolean) => void
  ): void;
}

type Store = State & Actions;

const useChatStore = create<Store>()(
  devtools((set, get): Store => {
    function wrapHandler<T extends object>(callback: (payload: T) => void) {
      return async function (payload: T | ErrorPayload) {
        if ('error' in payload) {
          console.warn(payload);
          set({ error: new Error(payload.error.message) });
        } else {
          return callback(payload);
        }
      };
    }

    function init(hostPlatformContext: HostPlatformContext) {
      // Init is called twice in dev cos of useEffect in HostedApp, making all on handlers register twice, meaning they are fired twice on incoming event
      if (get().hostPlatformContext) {
        return;
      }
      set(() => ({ hostPlatformContext }));

      const { socket, user } = hostPlatformContext;

      socket.on('connect_error', (error) => {
        set({ error: new Error(error.message) });

        const context = 'context' in error ? error.context : undefined;
        const xhr = context instanceof XMLHttpRequest ? (context as XMLHttpRequest) : undefined;
        console.error(`Connection error ${error.message}`, {
          error,
          responseText: xhr?.responseText,
          status: xhr?.statusText,
        });
      });

      socket.on(
        'error',
        wrapHandler(() => {})
      );

      socket.on(
        'chat-summaries:changed',
        wrapHandler((payload) => {
          set((state) => {
            const chats = state.chats.slice();
            if (!payload.shared) {
              return { chats };
            }

            const index = chats.findIndex((c) => c.id === payload.id);
            if (index !== -1) {
              chats[index] = payload;
            } else {
              chats.unshift(payload);
            }
            return { chats: chats.toSorted(chatSorter) };
          });
        })
      );

      socket.on(
        'chat-msg:from-ai:progress',
        wrapHandler((payload) => {
          set((state) => {
            const chat = state.chat?.value;
            if (!chat) {
              return {};
            }
            if ('error' in payload) {
              return {};
            }
            return {
              chat: {
                value: {
                  ...chat,
                  progressingBotMessage: 'error' in payload ? undefined : payload,
                },
              },
            };
          });
        })
      );

      socket.on(
        'chat-msg:created',
        wrapHandler((payload) => {
          set((state) => {
            const { message, chat: updatedChat } = payload;
            const chat = state.chat?.value;
            if (!chat || !chat.chat || chat.chat.id !== message.chatId) {
              return {};
            }
            if (message.role === 'ai' && 'error' in message.content) {
              console.warn(message.content.error);
            }

            return {
              chat: {
                value: {
                  ...chat,
                  chat: { ...chat.chat, otherUsers: updatedChat.otherUsers },
                  ...(message.role === 'ai' &&
                  chat.progressingBotMessage?.replyToMessageId === message.replyToMessageId
                    ? { progressingBotMessage: undefined }
                    : {}),
                  messages: [...chat.messages, message],
                },
              },
            };
          });
        })
      );

      socket.emit(
        'app:init',
        {
          sourceContext: hostPlatformContext.sourceContext,
          customContext: hostPlatformContext.customContext,
          groupingId: hostPlatformContext.groupingId,
          user: {
            name: user?.name,
            picture: user?.picture,
            source: user ? { system: 'auth0', id: user?.sub } : undefined, //TODO: check where user auth is from when add another way to authenticate
          },
        },
        wrapHandler((payload) => {
          set(() => ({
            app: { value: { ...payload.actions, ...payload.settings } },
            chats: payload.summaries?.toSorted(chatSorter),
          }));
          openChat();
        })
      );
    }

    function openChat(chatId?: string) {
      const {
        app: { value: appValue },
        sendMessage,
        hostPlatformContext,
        chat,
      } = get();

      if (!hostPlatformContext) {
        throw new Error('Init must be called before opening chat');
      }

      if (chatId) {
        set({ chat: { loading: true } });
        hostPlatformContext.socket.emit(
          'chat:load',
          { chatId },
          wrapHandler((payload) => {
            set((state) => ({
              chat: {
                value: {
                  ...state.chat?.value,
                  ...payload,
                },
              },
            }));
          })
        );
      } else {
        set({ chat: { value: { messages: [], sharingConfirmed: true } } });
        if (appValue?.autoAction && !chat) {
          sendMessage({
            actionId: appValue.autoAction.id,
            content: appValue.autoAction.displayName,
          });
        }
      }
    }

    function closeChat() {
      if (!get().chats?.length) {
        // TODO: is this a ui issue?
        throw new Error('Cannot close chat when there are no sessions');
      }
      set({ chat: undefined });
    }

    function sendMessage({ actionId, content: message }: ChatMessageInfo) {
      const { chatValue, socket } = getActiveChat(get());

      set((state) => ({
        chat: {
          value: {
            ...state.chat!.value!,
            sending: true,
          },
        },
      }));
      // TODO: add timeout

      if (!chatValue.chat) {
        const sharingConfirmed = !!get().chat?.value?.sharingConfirmed;
        const shared = getSharingConfirmationSupported(get()) ? sharingConfirmed : true;

        socket.emit(
          'chat:create',
          { actionId, message, shared },
          wrapHandler((payload) => {
            set((state) => {
              return {
                chat: {
                  value: {
                    ...state.chat?.value,
                    chat: payload.chat,
                    messages: [payload.message],
                    progressingBotMessage: { content: '', replyToMessageId: payload.message.id },
                    sending: false,
                  },
                },
              };
            });
          })
        );
      } else {
        socket.emit(
          'chat-msg:create',
          { chatId: chatValue.chat.id, request: { message } },
          wrapHandler((payload) => {
            set((state) => {
              const existingValue = state.chat!.value!;
              return {
                chat: {
                  value: {
                    ...existingValue,
                    messages: [...existingValue.messages, payload],
                    progressingBotMessage:
                      payload.to === 'ai'
                        ? { content: '', replyToMessageId: payload.id }
                        : undefined,
                    sending: false,
                  },
                },
              };
            });
          })
        );
      }
    }

    function setChatSharingConfirmed(sharingConfirmed: boolean) {
      const { chatValue } = getActiveChat(get());

      if (chatValue.messages.length) {
        throw new Error('Cannot change sharing confirmation after first message');
      }

      if (!getSharingConfirmationSupported(get())) {
        throw new Error('Sharing confirmation is not supported');
      }

      set((state) => ({
        chat: {
          value: {
            ...state.chat!.value!,
            sharingConfirmed,
          },
        },
      }));
    }

    function setMessageLiked(
      messageId: string,
      isLiked: boolean,
      comment: string,
      onSuccess: (isLiked: boolean) => void
    ) {
      const { chatValue, socket } = getActiveChat(get());
      const lastAiMessage = chatValue.messages.findLast(({ role }) => role === 'ai');

      if (!chatValue.chat?.id) {
        throw new Error(`Missing Chat id for sending feedback`);
      }
      if (lastAiMessage?.role !== 'ai' || lastAiMessage.id !== messageId) {
        throw new Error(`Can only send feedback on the last ai message`);
      }
      if (lastAiMessage.feedbacks.length && lastAiMessage.feedbacks.length > 0) {
        throw new Error(`Feedback on this message already exists`);
      }

      socket.emit(
        'chat-msg-feedback:create',
        { chatId: chatValue.chat.id, messageId, body: { isHelpful: isLiked, comment } },
        wrapHandler((payload) => {
          onSuccess(payload.isHelpful);
          set((state) => {
            const existingValue = state.chat!.value!;
            const updatedMessages = chatValue.messages.map((msg) => {
              if (msg.id === payload.messageId && msg.role === 'ai') {
                return { ...msg, feedbacks: [...msg.feedbacks, payload] };
              }
              return msg;
            });

            return { chat: { value: { ...existingValue, messages: updatedMessages } } };
          });
          return;
        })
      );
    }

    return {
      hostPlatformContext: undefined,
      error: undefined,
      app: { loading: true },
      chats: [],
      chat: undefined,
      openChat,
      closeChat,
      setChatSharingConfirmed,
      sendMessage,
      setMessageLiked,
      init,
    };
  })
);

export default useChatStore;

function chatSorter(a: ChatSummaryItem, b: ChatSummaryItem) {
  return a.summary.lastMessageAt > b.summary.lastMessageAt ? 1 : -1;
}

function getActiveChat({ hostPlatformContext, chat }: Store) {
  const socket = hostPlatformContext?.socket;
  const chatValue = chat?.value;
  if (!socket) {
    throw new Error('Socket not started');
  }
  if (!chatValue) {
    throw new Error('Cannot send message, there is no chat active');
  }

  return { chatValue, socket };
}

function getSharingConfirmationSupported({ app, hostPlatformContext }: Store) {
  return (
    !!app.value?.groupingSupported &&
    !!hostPlatformContext?.groupingId &&
    !!app.value?.sharingApprovalRequired
  );
}
