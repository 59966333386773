import { useAuth0 } from '@auth0/auth0-react';
import { runtimeEnv } from '@worknet/app-core';

export function useSafeLogout() {
  const { logout } = useAuth0();

  return function safeLogout() {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = `https://${runtimeEnv.auth0Domain}/v2/logout?client_id=${runtimeEnv.auth0ClientId}`;
    document.body.appendChild(iframe);

    logout({ openUrl: false }).then(() => setTimeout(() => document.body.removeChild(iframe), 500));
  };
}
