import { Box, Button, Icon, Stack, Text } from '@chakra-ui/react';
import { ChatSummaryItem } from '@worknet/models';
import { useMemo } from 'react';

import ArrowForward from '~/shared/components/ds2/assets/ArrowForward.svg?react';

import { getFormattedDate } from '../utils';

import ChatCard from './ChatCard';

export type ChatEvent = { name: 'openChat'; chatId: string } | { name: 'newChat' };
interface SummariesProps {
  chats: ChatSummaryItem[];
  onChatEvent: (evt: ChatEvent) => void;
}

export default function ChatsList({ chats, onChatEvent }: SummariesProps) {
  const chatsByDay = useMemo(() => groupByDay(chats), [chats]);

  return (
    <Stack flex={1} overflowY="hidden">
      <Button
        variant="primary"
        mb="10px"
        mx="21px"
        borderRadius="4px"
        gap="5px"
        height="60px"
        flexShrink={0}
        display="flex"
        justifyContent="space-between"
        onClick={() => onChatEvent({ name: 'newChat' })}
      >
        <Box display="flex" flexDirection="column" gap="4px">
          <Text fontWeight="bold" textAlign="start">
            Start Chat
          </Text>
          <Text fontWeight="normal" textAlign="start" color="#EDF2F7">
            Start a chat for quick help
          </Text>
        </Box>
        <Box>
          <Icon boxSize="16px" fill="white" as={ArrowForward} />
        </Box>
      </Button>
      <Stack gap="20px" overflowY="auto">
        {chatsByDay.map((chats) => {
          const date = getFormattedDate(new Date(chats.date));
          return (
            <Box key={date}>
              <Text
                fontSize="chatSmallText"
                mb="8px"
                ml="59px"
                color="greyNatural"
                fontWeight={600}
              >
                {date}
              </Text>
              {chats.chats.map((chat) => (
                <ChatCard
                  key={chat.id}
                  chat={chat}
                  onClick={() => onChatEvent({ name: 'openChat', chatId: chat.id })}
                />
              ))}
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
}

function groupByDay(chats: ChatSummaryItem[]) {
  const chatsByDayMap = chats.reduce<Record<string, ChatSummaryItem[]>>((acc, chat) => {
    const lastMessageDate = new Date(chat.summary.lastMessageAt);
    const startOfDay = new Date(
      lastMessageDate.getFullYear(),
      lastMessageDate.getMonth(),
      lastMessageDate.getDate()
    );
    const day = startOfDay.toISOString();
    acc[day] ??= [];
    acc[day].unshift(chat);
    return acc;
  }, {});

  return Object.keys(chatsByDayMap)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .map((day) => ({
      date: day,
      chats: chatsByDayMap[day],
    }));
}
