import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { createContext, useContext } from 'react';
import { useAsync } from 'react-use';

export type ContextType = ReturnType<typeof useWorknetProvider>;

export const authContext = createContext({} as ContextType);

type WorknetValues = { permissions: string[]; org_name: string; org_id: string };

export function useWorknetProvider() {
  const { isLoading, error, getAccessTokenSilently, user, logout, ...rest } = useAuth0();
  const needsAuthentication = !isLoading && !error && !user;
  const {
    loading: tokenLoading,
    error: tokenError,
    value,
  } = useAsync(async () => {
    if (!user) {
      return undefined;
    }
    const token = await getAccessTokenSilently({});
    const decoded: Record<string, unknown> = jwtDecode(token, { header: false });
    return { ...decoded, permissions: (decoded.permissions as string[]) || [] } as WorknetValues;
  }, [user, getAccessTokenSilently]);

  const isWorknetAdmin = value?.permissions?.includes('worknet-admin');
  const isTenantAdmin = value?.permissions?.includes('tenant-admin');

  const result = {
    isLoading: needsAuthentication ? false : isLoading || tokenLoading || !value?.permissions,
    error: error || tokenError,
    ...value,
    isWorknetAdmin,
    isTenantAdmin,
    logout: (options?: LogoutOptions) => {
      logout({
        logoutParams: { returnTo: window.location.origin },
        ...options,
      });
    },
  };

  return { ...rest, ...result, getAccessTokenSilently, user };
}

export function useWorknetAuth() {
  return useContext(authContext);
}
