import { UseToastOptions, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { DefaultError } from '@tanstack/react-query';
import { showErrorToast } from '@worknet/app-core';

export function useErrorToast<TError = DefaultError>(
  error: string | TError | null | undefined,
  options?: UseToastOptions
) {
  const toast = useToast();
  useEffect(() => {
    if (error) {
      showErrorToast<TError>(toast, error, options);
    }
  }, [error, toast, options]);
}
