import { datadogLogs } from '@datadog/browser-logs';

import { runtimeEnv } from './runtimeEnv';

export const datadogInit = (serviceName: string) => {
  if (import.meta.env.DEV) {
    return;
  }

  window.addEventListener('error', (event) => {
    datadogLogs.logger.error('Caught error', event);
  });

  window.addEventListener('unhandledrejection', (event) => {
    const { reason: error, ...rest } = event;
    datadogLogs.logger.error('Unhandled promise rejection', { error, ...rest });
  });

  datadogLogs.init({
    clientToken: 'pub55f807e3bc1ef8aa7f284f0ec35c75cc',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: false, // TODO: too verbose, hopefully our listeners will be enough
    sessionSampleRate: 100,
    env: runtimeEnv.stage,
    version: runtimeEnv.version,
    forwardConsoleLogs: ['error', 'warn'],
    service: serviceName,
  });
};
