import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { showErrorToast } from '@worknet/app-core';

import { useSafeLogout } from './useSafeLogout';

export default function useErrorHandler(error?: Error) {
  const logout = useSafeLogout();
  const toast = useToast();

  useEffect(() => {
    if (error) {
      showErrorToast(toast, error, { title: 'Error' });
      logout();
    }
  }, [error]);
}
